$tablet-width: 768px;
$desktop-width: 1024px;
$main-color: #00A48C;

.lawyerHeadlineImageContainer {
  height: 400px;
  width: 600px;
  background: $main-color;
  color: white;
  font-size: 47px;
  margin-left: 60px;
}

.lawyerHeadlineTitle {
  margin-top: 140px;
}

.lawyerHeadlineText {
  margin-left: 68px;
  line-height: 52px;
}
.lawyerHeadlineContainer {
  margin-top: 15px;
}

.lawyerHeadImage {
  height: 100%;
  width: 100%;
}