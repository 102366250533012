$tablet-width: 768px;
$desktop-width: 1024px;
$main-color: #00A48C;

.companyHeadline {
  font-size: 48px;
  font-weight: 400;
  color: black;
  @media (max-width: #{$tablet-width}) {
    font-size: 35px;
  }
}

.companyImage {
  margin-top: 90px;
  text-align: center;
}

.companyHead {
  color: $main-color;
  text-align: center;
  font-size: 45px;
  font-weight: 600;
}

.companyText {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 300;
  color: black;
  max-width: 1100px;
}

.quality {
  padding-top: 28px;
  text-align: center;
  color: $main-color;
  font-size: 39px;
  font-weight: 650;
  background: #eefffd;
  @media (max-width: #{$tablet-width}) {
    font-size: 28px;
    margin-left: 0;
  }
}

.margin {
  margin-right: 130px;
  margin-left: 35px;
  margin-top: 85px;
  @media (max-width: #{$tablet-width}) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

.secondQuality {
  text-align: center;
  color: $main-color;
  font-size: 28px;
  font-weight: 700;
  background: #eefffd;
  @media (max-width: #{$tablet-width}) {
    font-size: 18px;
    margin-left: 0;
  }
}

.qualityText {
  background: #eefffd;
  font-size: 18px;
  padding-top: 52px;
  padding-bottom: 50px;
  line-height: 25px;
}

.margin-left {
  margin-left: 18px;
  margin-right: 10px;
}

.socialText {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 50px;
  max-width: 1050px;
}

.socialIcon {
  margin-bottom: 60px;
}

.history {
  margin-top: 46px;
  text-align: center;
  color: $main-color;
  font-size: 40px;
  font-weight: 650;
  margin-left: 40px;
  @media (max-width: #{$tablet-width}) {
    font-size: 28px;
    margin-left: 0;
  }
}

.secondHistory {
  text-align: center;
  color: $main-color;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 50px;
  margin-left: 40px;
  @media (max-width: #{$tablet-width}) {
    font-size: 18px;
    margin-left: 0;
  }
}

.socialEngagement {
  margin-top: 80px;
  text-align: center;
  color: $main-color;
  font-size: 40px;
  font-weight: 650;
  margin-left: 40px;
  @media (max-width: #{$tablet-width}) {
    font-size: 28px;
    margin-left: 0;
  }
}

.secondSocialEngagement {
  text-align: center;
  color: $main-color;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 40px;
  margin-left: 40px;
  @media (max-width: #{$tablet-width}) {
    font-size: 18px;
    margin-left: 0;
  }
}
.member {
  margin-top: 46px;
  text-align: center;
  color: $main-color;
  font-size: 40px;
  font-weight: 650;
  margin-left: 40px;
  @media (max-width: #{$tablet-width}) {
    font-size: 28px;
    margin-left: 0;
  }
}

.secondMember {
  text-align: center;
  color: $main-color;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: 40px;
  @media (max-width: #{$tablet-width}) {
    font-size: 28px;
    margin-left: 0;
  }
}

.mainColor {
  color: $main-color;
  font-weight: 500;
}

.historyText {
  font-size: 18px;
  font-weight: 300;
  color: black;
}

.companyContentContainer {
  margin-top: 35px;
}

.companyContentRow {
  margin-left: 10px;
}

.partnerRow {
  margin-left: 22px;
}

.greenBackground {
  background: #eefffd;
}

.partner {
  height: 413px;
  width: 505px;
  border-color: $main-color;
  border-style: solid;
  border-width: 2px;
  @media (max-width: #{$tablet-width}) {
    width: 95%;
    height: 100%;
  }
}

.ivoeLogo {
  margin-top: 30px;
  text-align: center;
  @media (max-width: #{$tablet-width}) {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.fencaLogo {
  margin-top: 10px;
  text-align: center;
  @media (max-width: #{$tablet-width}) {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.secondPartner {
  height: 413px;
  width: 505px;
  border-color: $main-color;
  border-style: solid;
  border-width: 2px;
  margin-left: 30px;
  @media (max-width: #{$tablet-width}) {
    width: 95%;
    height: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
}

.partnerText {
  font-size: 18px;
  margin-top: 35px;
  margin-left: 35px;
  margin-right: 25px;
}