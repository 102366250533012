$tablet-width: 768px;
$desktop-width: 1024px;
$main-color: #00A48C;

.homepageHeadlineText {
  margin-top: 140px;
  line-height: 52px;
  color: white;
  font-size: 45px;
  margin-left: 110px;
  @media (max-width: #{$tablet-width}) {
    margin-left: 38px;
    font-size: 32px;
    margin-top: 37px;
    line-height: 40px;
    margin-bottom: 34px;
  }
}

.homepageHeadlineContainer {
  background: $main-color;
}

.homepageHeadImage {
  height: 100%;
  width: 100%;
  @media (max-width: #{$tablet-width}) {
    display: none;
  }
}

.realHomepageContentContainer {
  margin-top: 15px;
  @media (max-width: #{$tablet-width}) {
    padding: 0;
  }
}
