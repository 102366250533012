$main-color: #00A48C;
$tablet-width: 768px;
$desktop-width: 1024px;


.footerRow {
  @media (max-width: #{$tablet-width}) {
    display: none;
  }
}

.footerList {
  @media (max-width: #{$tablet-width}) {
    list-style-type: none;
  }
}

.footerListElement {
  margin-bottom: 30px;
}

.footerPhone {
  display: none;
  @media (max-width: #{$tablet-width}) {
    display: flex;
    list-style-type: none;
  }
}

.footerContainer {
  background: $main-color;
  color: white;
  font-weight: bold;
  height: 165px;
  margin-top: 60px;
  @media (max-width: #{$tablet-width}) {
    height: 200px;
  }
  a:hover {
    color: white;
    text-decoration: none;
  }
  a {
    margin-left: 5px;
    color: white;
    text-decoration: none;
  }
}

.footerIvoeLogo {
  width: 110px;
  height: 110px;
  margin-right: 50px;
  margin-top: 20px;
  @media (max-width: #{$tablet-width}) {
    text-align: right;
  }
}

.footerItem {
  margin-top: 40px;
}

.footerArrow {
  margin-top: -3px;
}
