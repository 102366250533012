$tablet-width: 768px;
$desktop-width: 1024px;
$main-color: #00A48C;

.legalInformationHeadlineImageContainer {
  height: 400px;
  width: 600px;
  background: $main-color;
  color: white;
  font-size: 47px;
  margin-left: 60px;
}

.legalInformationHeadlineTitle {
  margin-top: 140px;
}

.legalInformationHeadlineText {
  margin-left: 68px;
  line-height: 52px;
}
.legalInformationHeadlineContainer {
  margin-top: 15px;
}

.legalInformationHeadImage {
  height: 400px;
  width: 600px;
  margin-left: -12px;
  @media (max-width: #{$tablet-width}) {
    margin-left: 0;
    width: 600px;
    height: 400px;
  }
}