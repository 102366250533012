$main-color: #00A48C;

.legalInformationHeadline {
  font-size: 40px;
  font-weight: 400;
  color: black;
}

.legalInformationImage {
  margin-top: 90px;
  text-align: center;
}

.legalInformationHead {
  color: $main-color;
  text-align: center;
  font-size: 45px;
  font-weight: 600;
}

.legalInformationContentContainer {
  margin-top: 35px;
}

.legalInformationContentRow {
  margin-left: 10px;
  margin-top: 15px;
}

.lastLegalInformationContentRow {
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 50px;
}

.legalInformationText {
  font-size: 18px;
  color: black;
}

.legalInformationTextBold {
  font-size: 18px;
  color: black;
  font-weight: bold;
}