html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative
}

code {
  font-family: 'Helvetica', sans-serif !important;
}