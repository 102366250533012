$tablet-width: 768px;
$desktop-width: 1024px;
$main-color: #00A48C;

.headerContainer {
  margin-top: 45px;
  display: grid;
  @media (max-width: #{$tablet-width}) {
    margin-top: 15px;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 154, 129)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border: none;
}

.nav-link {
  color: black;
  vertical-align: bottom;
}

.nav-item {
  font-size: 20px;
}

.last-nav-item {
  font-size: 20px;
}

.headerList {
  margin-top: -15px;
  display: flex;
  height: 100%;
}

.headerItem {
  display: inline-block;
  align-self: flex-end;
  padding-top: 75px;
  margin-right: 70px;
  @media (max-width: #{$tablet-width}) {
    padding-top: 5px;
    margin-right: 0;
  }
}

.headerItemSize {
  font-size: 18px;
}

.lastHeaderItem {
  display: inline-block;
  align-self: flex-end;
  padding-top: 80px;
  margin-right: 30px;
  @media (max-width: #{$tablet-width}) {
    display: none;
  }
}

.logo {
  width: 265px;
  height: 110px;
  margin-left: 25px;
  @media (max-width: #{$tablet-width}) {
    width: 177px;
    height: 72px;
    margin-left: 0;
  }
}

.logoCol {
  @media (max-width: #{$tablet-width}) {
    margin-left: 0px;
  }
}

.loginCol {
  display: none;
  @media (max-width: #{$tablet-width}) {
    display: inline;
    margin-left: 15px;
    margin-top: 25px;
  }
}

.toogleCol {
  display: none;
  @media (max-width: #{$tablet-width}) {
    display: inline;
    margin-left: -27px;
    margin-top: 25px;
  }
}

.test {
  @media (max-width: #{$tablet-width}) {
    display: contents;
  }
}