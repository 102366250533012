$tablet-width: 768px;
$desktop-width: 1024px;
$main-color: #00A48C;

.companyHeadlineImageContainer {
  height: 400px;
  width: 600px;
  background: $main-color;
  color: white;
  font-size: 47px;
  margin-left: 60px;
}

.companyHeadlineTitle {
  margin-top: 140px;
}

.companyHeadlineText {
  margin-left: 68px;
  line-height: 52px;
}
.companyHeadlineContainer {
  margin-top: 15px;
}

.headImage {
  height: 400px;
  width: 600px;
  margin-left: -12px;
  @media (max-width: #{$tablet-width}) {
    margin-left: 0;
    width: 600px;
    height: 400px;
  }
}