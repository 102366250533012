$main-color: #00A48C;

.laywerHeadline {
  font-size: 40px;
  font-weight: 400;
  color: black;
}

.laywerImage {
  margin-top: 90px;
  text-align: center;
}

.laywerHead {
  color: $main-color;
  text-align: center;
  font-size: 45px;
  font-weight: 600;
}

.laywerContentContainer {
  margin-top: 35px;
}

.laywerContentRow {
  margin-left: 10px;
  margin-top: 15px;
}

.lastLaywerContentRow {
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 300px;
}

.laywerText {
  font-size: 18px;
  color: black;
}

.laywerTextBold {
  font-size: 18px;
  color: black;
  font-weight: bold;
}