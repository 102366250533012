$main-color: #00A48C;
$tablet-width: 768px;
$desktop-width: 1024px;

.farewellHeadLine {
  font-size: 45px;
  color: black;
  @media (max-width: #{$tablet-width}) {
    font-size: 35px;
  }
}

.headText {
  margin-top: 15px;
  font-size: 21px;
  font-weight: 300;
}

.headContainer {
  margin-left: 50px;
}

.underline {
  text-decoration-color: $main-color;
  font-weight: 500;
}

.farewellContentContainer {
  margin-top: 55px;
  @media (max-width: #{$tablet-width}) {
    margin-top: 35px;
    margin-left: 38px;
    max-width: 85%;
  }
}

.homepageContentRow {
  //padding-left: 0 !important;
  //padding-right: 0 !important;
}

.reminderLink {

}